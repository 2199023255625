<template>
  <div class="container px-3 pb-12 mx-auto">
    <router-link to="/swot/" class="px-3 py-2 text-white bg-blue-400 rounded text-md"
      ><span class="text-md">&#10094;&#10094;</span>&nbsp;&nbsp; All Active Clients</router-link
    >
    <section class="flex flex-wrap mt-8 mb-2">
      <div class="flex justify-between w-full mb-10">
        <h1 class="flex items-center mr-16 text-4xl font-bold text-blue-900 uppercase dark:text-blue-600">
          {{ client_id }}
          <!-- <button class="copy-all-icon">
            <svg
              class="w-10 h-10 ml-2 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              />
            </svg>
          </button> -->
        </h1>
        <div class="is-pulled-right archive">
          <button class="flex items-center px-3 py-2 text-yellow-100 bg-yellow-500 rounded" v-on:click="archiveClient">
            <svg
              class="w-5 h-5 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
              />
            </svg>
            Archive Channel
          </button>
        </div>
      </div>
      <!-- <div class="mt-5 mb-12 ">
        <input
          type="file"
          multiple
          accept="image/*"
          @change="detectFiles($event.target.files)"
        />
        <img class="w-48 h-auto my-3" :src="picture" />
        <div
          v-if="progressUpload < 100 && progressUpload > 0"
          class="progress-bar"
          :style="{ width: progressUpload + '%' }"
        >
          {{ progressUpload }}%
        </div>
        <div v-if="picture">
          <p class="flex items-center text-blue-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Saved
          </p>
        </div>
      </div> -->

      <SwotSection
        v-for="section in sections"
        :key="section.collection"
        :title="section.title"
        :collection="section.collection"
        :clientId="client_id"
      />
    </section>
  </div>
</template>

<script>
  import { repgen } from "@/firebase";
  import SwotSection from "@/components/swot/SwotSection.vue";
import { doc, onSnapshot, updateDoc } from "@firebase/firestore";

  export default {
    components: {
      SwotSection,
    },
    data() {
      return {
        sections: [
          {
            title: "PARKING LOT",
            collection: "parking_lot",
          },
          {
            title: "EXEC SUMMARY",
            collection: "exec_summary",
          },
          {
            title: "STRENGTHS",
            collection: "strengths",
          },
          {
            title: "WEAKNESSES",
            collection: "weaknesses",
          },
          {
            title: "OPPORTUNITIES",
            collection: "opportunities",
          },
          {
            title: "THREATS",
            collection: "threats",
          },
          {
            title: "RECOMMENDATIONS",
            collection: "recommendations",
          },
          {
            title: "INFO",
            collection: "info",
          },
          {
            title: "QUOTES",
            collection: "quotes",
          },
          {
            title: "ADD",
            collection: "adds",
          },
        ],
        allSWOT: "",
        parkingLots: [],
        client_id: this.$route.params.clientID,
        //archived: false,
      };
    },
    mounted() {
      console.log(this.client_id);
      onSnapshot(doc(repgen, "swots", this.$route.params.clientID), (doc) => {
        this.clients = [];
        let data = {
          client_name: doc.id,
          publishedAt: doc.data().publishedAt,
          archive: doc.data().archive,
        };
        this.clients.push(data);
      })
    },
    created() {},
    methods: {
      archiveClient: function() {
        updateDoc(doc(repgen, "swots", this.$route.params.clientID), {
          archive: true,
        });
        this.archived = true;
        this.$router.push("/");
      },
      closeNotification: function() {
        this.message = "";
      },
      handleCopyStatus(status) {
        this.copySucceeded = status;
      },
    },
    watch: {},
  };
</script>

<style></style>
